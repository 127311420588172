import {
  Modal, Form, notification, Select, Input, Button,
} from 'antd';
import {useEffect, useState} from 'react';
import _ from 'lodash';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import api from '../api';

const defaultValues = {
  enable_preprocessing: false,
};

export default function PoolModal({
  visible, closeModal, selectedPool, fetchAPIData, current, customers, engines,
}) {
  const [form] = Form.useForm();
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const submitForm = (values) => {
    setLoadingSuccess(true);
    const payload = selectedPool.id ? { ...values, id: selectedPool.id, prompt: !values.prompt ? null : values.prompt } : values;

    api
      .request({
        url: selectedPool.id ? `/pools/${selectedPool.id}` : '/pools',
        method: selectedPool.id ? 'PATCH' : 'POST',
        data: payload,
      })
      .then(() => {
        notification.success({
          message: `Pool successfully ${selectedPool.id ? 'edited' : 'created'}`,
          placement: 'top',
        });
        setLoadingSuccess(false);
        form.resetFields();
        fetchAPIData(current);
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        setLoadingSuccess(false);
      });
  };

  useEffect(() => {
    selectedPool && form.setFieldsValue({...defaultValues, ...selectedPool});
  }, [form, selectedPool, visible]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setTimeout(() => form.resetFields(), 300);
        closeModal();
      }}
      okButtonProps={{
        form: 'poolForm',
        htmlType: 'submit',
        key: 'submit',
      }}
      okText='Confirm'
      destroyOnClose
      loading={loadingSuccess}
    >
      <p style={{ textAlign: 'center', fontSize: 'large' }}>
        {' '}
        Pool name: {selectedPool.name}
      </p>
      <Form
        form={form}
        id='poolForm'
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item
          name='customer_id'
          rules={[{ required: true, message: 'Please select customer' }]}
          label='Customer'
        >
          <Select
            disabled={!_.isEmpty(selectedPool)}
            allowClear
            optionFilterProp='children'
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {customers
              && customers.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
            name='engine'
            label='Engine'
        >
          <Select
              allowClear
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            {engines
                && engines.map((engine, index) => (
                      <Select.Option key={index} value={engine}>
                        {engine}
                      </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item name='name' rules={[{ required: true, message: 'Please enter pool name' }]} label='Name'>
          <Input/>
        </Form.Item>
        <Form.Item name='prompt' label='Prompt'>
          <Input />
        </Form.Item>
        <Form.List
          name='aliases'
          rules={[
            {
              validator: async (_, aliases) => {
                if (!aliases || aliases.length < 1) {
                  return Promise.reject(new Error('At least 1 alias is required'));
                }

                for (const alias of aliases) {
                  if (!alias || alias === '') {
                    return Promise.reject(new Error('Aliases must not be empty!'));
                  }
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 ? 'Aliases' : null}
                  className={'pool-form-alias-input'}
                  key={index}
                >
                  <Form.Item {...field}>
                    <Input />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add alias
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}
