import { Routes, Route, Navigate } from 'react-router-dom';
import Admin from './containers/admin/Admin';
import Login from './containers/login/Login';

export default function AuthenticatedApp() {
  return (
    <Routes>
      <Route path='/admin' element={<Admin />} />
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
}
