import {
  Modal,
  Form,
  Input,
  Select,
  notification,
  Spin,
  Upload,
  Button,
} from 'antd';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import api, {readToken, API_ROOT} from '../api';

const { Option } = Select;

export default function NewCollectionModal({
  visible,
  closeModal,
  getCollections,
  customers,
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pools, setPools] = useState([]);
  const [loadingPools, setLoadingPools] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  const submit = async (values) => {
    const payload = {
      slug: values.slug,
      name: values.name,
      customer_id: values.customer_id,
      pool_id: values.pool,
      alias: values.alias,
      image,
    };
    setLoading(true);
    try {
      await api.post('/collections', payload);
      notification.success({
        message: 'Collection successfully added',
        position: 'top',
      });
      form.resetFields();
      setLoading(false);
      closeModal();
      getCollections();
    } catch ({ response }) {
      notification.error({
        message: `${response.data.message}`,
        placement: 'top',
      });
      setLoading(false);
    }
  };

  const fetchPools = async (id) => {
    setLoadingPools(true);
    try {
      const result = await api.get('/pools', {
        params: { customer_id: id, $limit: 'all' },
      });
      setPools(result.data);
      setLoadingPools(false);
    } catch ({ response }) {
      setLoadingPools(false);
      console.error(response.data.message);
    }
  };

  const fetchItems = async (id) => {
    if (id) {
      setLoadingItems(true);
      try {
        const result = await api.get('/items', {
          params: {
            pool_id: id,
            $limit: 'all',
            $select: ['id', 'name', 'alias'],
          },
        });
        setItems(result.data);
        setLoadingItems(false);
      } catch ({ response }) {
        setLoadingItems(false);
        console.error(response.data.message);
      }
    }
  };

  const handleChange = (info) => {
    if (info) {
      form.resetFields(['alias']);

      const { xhr } = info.file;
      if (xhr && xhr.status === 201 && xhr.response) {
        setImage(JSON.parse(xhr.response).url);
      }
    }
  };

  const clearUpload = (e) => {
    if (e) {
      setImage(null);
      form.resetFields(['upload']);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      destroyOnClose
      okButtonProps={{
        form: 'newCollectionForm',
        htmlType: 'submit',
        key: 'submit',
      }}
    >
      <Spin spinning={loading}>
        <Form
          style={{ marginTop: '15px' }}
          id='newCollectionForm'
          onFinish={submit}
          autoComplete='off'
          form={form}
        >
          <Form.Item
            name='slug'
            rules={[{ required: true, message: 'Please enter slug!' }]}
          >
            <Input placeholder='Slug' />
          </Form.Item>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Please enter  name!' }]}
          >
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item
            name='customer_id'
            rules={[{ required: true, message: 'Please select customer' }]}
          >
            <Select
              allowClear
              placeholder='Customer'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                form.resetFields(['pool', 'alias']);
                fetchPools(e);
              }}
            >
              {customers
                && customers.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='pool'
            rules={[{ required: true, message: 'Please select pool!' }]}
          >
            <Select
              loading={loadingPools}
              allowClear
              placeholder='Pools'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                form.resetFields(['alias']);
                fetchItems(e);
              }}
              disabled={
                !form.getFieldValue('customer_id')
                || !pools
                || pools.length === 0
              }
            >
              {pools
                && pools.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='alias'
            rules={[
              {
                required: !form.getFieldValue('alias') && image === null,
                message: 'Please select alias or upload an image!',
              },
            ]}
          >
            <Select
              loading={loadingItems}
              allowClear
              placeholder='Alias'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) => (
                option.children
                  .join()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              )}
              disabled={
                !form.getFieldValue('pool') || !items || items.length === 0
              }
              onChange={clearUpload}
            >
              {items
                && items.map((i) => (
                    <Option key={i.id} value={i.alias}>
                      {i.alias} - {i.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>Or</div>
          <Form.Item
            name='upload'
            style={{ margin: '24px 0px 0px 0px', textAlign: 'center' }}
          >
            <Upload
              onChange={handleChange}
              action={`${API_ROOT}/upload`}
              headers={{
                Authorization: readToken(),
              }}
              maxCount={1}
              listType='picture'
            >
              <Button
                disabled={
                  !form.getFieldValue('customer_id')
                  || !pools
                  || pools.length === 0
                }
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            </Upload>
            ,
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
