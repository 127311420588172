import {Pagination, Table} from 'antd';
import {useState} from 'react';

export default function PaginatedTable({
  dataGrab, columns, data, onPageChange, loading, ...rest
}) {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  return (
    <>
      <Table
        dataSource={data.data}
        columns={columns}
        loading={loading}
        style={{ paddingTop: '40px' }}
        pagination={false}
        rowKey='id'
        key='id'
        {...rest}
      />
      <Pagination
        onChange={async (page, pageSize) => {
          setCurrent(page);
          setPageSize(pageSize);
          onPageChange(page, pageSize);
        }}
        style={{ paddingBottom: '40px' }}
        total={data.total}
        pageSize={pageSize}
        defaultPageSize={pageSize}
        defaultCurrent={1}
        current={current}
        showSizeChanger={false}
      />
    </>
  );
}
