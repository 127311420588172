import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button, Form, Input, Modal, notification, Tabs,
} from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from '../../api';

const { TabPane } = Tabs;

export default function AddEditCustomer({ changePage, customer }) {
  const [form] = Form.useForm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    customer && form.setFieldsValue({ ...customer });
  }, [form, customer]);

  const submitForm = (values) => {
    setLoading(true);

    let payload;

    if (customer?.id) {
      payload = {
        id: customer.id,
        ...values,
      };
    } else {
      payload = {
        ...values,
      };
    }

    api
      .request({
        url: customer?.id ? `/customers/${customer?.id}` : '/customers',
        method: customer?.id ? 'PATCH' : 'POST',
        data: payload,
      })
      .then((res) => {
        notification.success({
          message: `Customer successfully ${
            customer?.id ? 'edited' : 'created'
          }`,
          placement: 'top',
        });
        setLoading(false);
        changePage(0, null);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    setShowDeleteModal(false);
  };

  const deleteCustomer = async () => {
    try {
      setLoading(true);
      await api.delete(`/customers/${customer.id}`);
      notification.success({
        message: 'Customer successfully deleted',
      });
      changePage(0, null);
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Error deleting Customer.',
      });
    } finally {
      setLoading(false);
    }
    setShowDeleteModal(false);
  };

  return (
    <PageWrapper>
      <Header>
        <Title>
          <ArrowLeftOutlined
            size={16}
            className="leftArrow"
            onClick={() => changePage(0, null)}
          />
          {customer ? (
            <>
              <h3>{customer.name}</h3>
              <p>Client ID: {customer.id}</p>
            </>
          ) : (
            <h3>Add New Client</h3>
          )}
        </Title>

        <WidgetWrapper>
          {customer && (
            <Button
              onClick={() => setShowDeleteModal(true)}
              className="deleteButton"
            >
              Delete Client
            </Button>
          )}
          <Button onClick={() => changePage(0, null)} className="cancelButton">
            Cancel
          </Button>
          <Button className="saveButton" onClick={() => form.submit()}>
            Save
          </Button>
        </WidgetWrapper>
      </Header>
      {customer && (
        <Modal
          title={<b>Delete Client</b>}
          visible={showDeleteModal}
          className="delete-modal"
          loading={loading}
          footer={[
            <Button key="delete" type="danger" onClick={deleteCustomer}>
              Delete Client
            </Button>,
            <Button
              key="cancel"
              type="primary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>,
          ]}
        >
          <b>{customer.name}</b>
        </Modal>
      )}

      <Tabs defaultActiveKey="settings" className="tab">
        <TabPane tab="Settings" key="settings">
          <Form
            form={form}
            layout="vertical"
            id="addEditForm"
            onFinish={submitForm}
            autoComplete="off"
            initialValues={{
              hostnames: customer?.hostnames ? customer.hostnames : [''],
            }}
          >
            {!customer && (
              <Form.Item
                name="name"
                label="Client Name"
                rules={[
                  { required: true, message: 'Please provide client name' },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
                name="feed"
                label="Feed"
                rules={[
                  { required: true, message: 'Please provide feed url' },
                  { type: 'url', message: 'This field must be a valid url.' },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                name="feed_check_interval"
                label="Feed Check Interval"
                rules={[
                  { required: true, message: 'Please provide feed check interval' },
                ]}
            >
              <Input type={'number'} />
            </Form.Item>

            <Form.List name="hostnames">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label={`URL ${index + 1}`} key={index}>
                      <Form.Item
                        {...field}
                        rules={[
                          {
                            required: true,
                            message: 'URL is required',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: '512px',
                          }}
                        />
                      </Form.Item>

                      <div
                        style={{
                          width: '512px',
                          textAlign: 'right',
                          marginTop: -15,
                        }}
                      >
                        {index > 0 && (
                          <Button
                            style={{
                              width: '108px',
                              background: '#F5F5F5',
                            }}
                            onClick={() => remove(field.name)}
                            icon={<DeleteOutlined />}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => add()}
                      style={{
                        width: '148px',
                        background: '#F5F5F5',
                      }}
                      icon={<PlusCircleOutlined />}
                    >
                      Add URL
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </TabPane>
      </Tabs>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  padding: 15px;
  background: #fff;
  width: 100%;
  height: 100%;

  .customers-table {
    th {
      font-weight: bold;
    }
  }

  .tab {
    text-align: left;
  }

  .tab input {
    width: 512px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 8px;

  h1 {
    font-weight: bold;
    font-size: 18px;
  }
`;

const Title = styled.div`
  display: flex;

  .leftArrow {
    padding-top: 15px;
  }

  h3 {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 3px;
    font-size: 22px;
    font-weight: 500;
    color: black;
  }
  p {
    padding-top: 10px;
    color: grey;
    font-size: 14px;
  }
`;

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding: 10px 0;

  .deleteButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff4d4f;
    background: #fff;
    color: #ff4d4f;
    width: 111px;
    height: 32px;
    padding: 5px 16px;
    margin-right: 48px;
  }

  .cancelButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: black;
    width: 62px;
    height: 32px;
    padding: 5px 16px;
    margin-right: 8px;
  }

  .saveButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1890ff;
    color: #fff;
    width: 62px;
    height: 32px;
    padding: 5px 16px;
  }
`;
