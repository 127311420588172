import {useEffect, useState} from 'react';
import {
  Button, notification, Popconfirm, Space, Tag, Table,
} from 'antd';
import {DeleteTwoTone, EditTwoTone, PlusOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import PoolModal from '../modals/PoolModal';
import api from '../api';

export default function Pools() {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedPool, setSelectedPool] = useState({});
  const [customers, setCustomers] = useState([]);
  const [engines, setEngines] = useState([]);
  const [current] = useState(1);

  useEffect(() => {
    fetchPools();
    fetchCustomers();
    fetchEngines();
  }, []);

  const fetchPools = () => {
    setLoading(true);
    api
      .get('/pools?$limit=1000')
      .then((res) => {
        setPools(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setPools([]);
      });
  };

  const fetchEngines = () => {
    setLoading(true);
    api
      .get('/engines')
      .then((res) => setEngines(res.data.engines))
      .catch((err) => console.error(err));
  };

  const fetchCustomers = () => {
    setLoading(true);
    api
      .get('/customers')
      .then((res) => setCustomers(res.data.data))
      .catch((err) => console.error(err));
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Engine', dataIndex: 'engine', key: 'engine' },
    {
      title: 'Customer',
      dataIndex: 'customer_id',
      key: 'customer_id',
      render: (text, render) => {
        const customer = customers.find(
          (customer) => customer.id === render.customer_id,
        );
        // return customer.name;
        let color = 'blue';
        if (customer) {
          if (customer.name === 'swap.com') {
            color = 'blue';
          } else if (customer.name === 'footshop.cz') {
            color = 'green';
          } else if (customer.name === 'osta.ee') {
            color = 'gold';
          } else if (customer.name === 'FIND.Fashion') {
            color = 'cyan';
          }
          return <Tag color={color}>{customer.name}</Tag>;
        }
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, render) => (
          <ActionWrapper>
            <EditTwoTone
              style={{ fontSize: '25px' }}
              twoToneColor='#69c0ff'
              onClick={async () => {
                setSelectedPool(render);
                selectedPool && setVisible(true);
              }}
            />
            <Space size='middle'>
              <Popconfirm
                placement='top'
                title='Are you sure you want to delete this pool?'
                onConfirm={() => {
                  deletePool(render);
                }}
                okText='Yes'
                cancelText='No'
              >
                <DeleteTwoTone
                  style={{ fontSize: '25px' }}
                  twoToneColor='#c41c06'
                />
              </Popconfirm>
            </Space>
          </ActionWrapper>
      ),
    },
  ];

  const addNewPool = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setSelectedPool({});
  };

  const deletePool = async (pool) => {
    try {
      setLoading(true);
      await api.delete(`/pools/${pool.id}`);
      notification.success({
        message: 'Pool successfully deleted',
      });
      await fetchAPIData(current);
    } catch (e) {
      notification.error({
        message: 'Error deleting pool.',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAPIData = async (page, pageSize = 10) => {
    const skip = (page - 1) * pageSize;
    try {
      setLoading(true);
      const response = await api.get('/pools');
      setPools(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <ButtonWrapper>
        <Button onClick={() => addNewPool()} className='addRuleButton'>
          <PlusOutlined /> Add new pool
        </Button>
      </ButtonWrapper>
       <Table
        dataSource={pools.data}
        columns={columns}
        loading={loading}
        style={{ paddingTop: '40px' }}
        pagination={false}
        rowKey='id'
        key='id'
      />
      <PoolModal
        visible={visible}
        closeModal={closeModal}
        selectedPool={selectedPool}
        fetchAPIData={fetchAPIData}
        current={current}
        customers={customers}
        engines={engines}
      />
    </>
  );
}

const ButtonWrapper = styled.div`
  .addRuleButton {
    margin-right: 10px;
  }
  text-align: left;
  padding: 10px 0;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
