import {
  Checkbox, Form, Input, Modal, Select, Spin,
} from 'antd';
import {useCallback, useEffect, useState} from 'react';
import {Option} from 'antd/es/mentions';
import {debounce} from 'lodash';
import {api, service} from '../api';

const defaultValues = {
  active: true,
};

export default function AddEditDemo({
  visible,
  closeModal,
  demo,
  onSuccess,
}) {
  const [form] = Form.useForm();
  const [pools, setPools] = useState({data: []});
  const [engines, setEngines] = useState({engines: []});
  const [customers, setCustomers] = useState({data: []});
  const [loading, setLoading] = useState(false);

  const [poolsService] = useState(service('/pools'));
  const [demosService] = useState(service('/demos'));
  const [customersService] = useState(service('/customers'));

  const submit = async () => {
    setLoading(true);
    try {
      if (demo && demo.id) {
        const data = {
          ...form.getFieldsValue(),
          id: demo.id,
        };
        await demosService.update(data);
      } else {
        await demosService.create(form.getFieldsValue());
      }
      setLoading(false);
      closeModal();
      form.resetFields();
      onSuccess();
    } catch (e) {
      setLoading(false);
    }
  };

  const searchPools = debounce(async (term = null) => {
    setPools((await poolsService.find({search: {term}})));
  }, 200);

  const searchCustomers = debounce(async (term = null) => {
    setCustomers((await customersService.find({search: {term}})));
  }, 200);

  const initialize = useCallback(async () => {
    setLoading(true);
    const pools = await poolsService.find({});
    const engines = (await api.get('/engines')).data;
    const customers = await customersService.find({});
    setPools(pools);
    setEngines(engines);
    setCustomers(customers);
    setLoading(false);
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [customersService, form, poolsService]);

  useEffect(() => {
    initialize().then((r) => {});
  }, [initialize]);

  useEffect(() => {
    if (demo && demo.pool) {
      form.setFieldsValue({
        ...defaultValues,
        ...demo,
      });
      setPools({
        data: [demo.pool],
      });
    }
  }, [demo, form]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      destroyOnClose
      okButtonProps={{
        form: 'demoForm',
        htmlType: 'submit',
        key: 'submit',
      }}
    >
      <Spin spinning={loading}>
        <Form
          style={{ marginTop: '15px' }}
          id='demoForm'
          onFinish={submit}
          autoComplete='off'
          form={form}
        >
          <Form.Item
            name='slug'
            rules={[{ required: true, message: 'Please enter slug!' }]}
          >
            <Input placeholder='demo.find.fashion/' />
          </Form.Item>
          <Form.Item
            name='listingTitle'
            rules={[{ required: true, message: 'Please enter listing title!' }]}
          >
            <Input placeholder='Listing Title' />
          </Form.Item>
          <Form.Item
            name='currency'
          >
            <Input placeholder='Currency' />
          </Form.Item>
          <Form.Item
            name='pool_id'
            rules={[{ required: true, message: 'Please select pool' }]}
          >
            <Select
              allowClear
              placeholder='Pool'
              optionFilterProp='children'
              showSearch
              onSearch={searchPools}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {pools.data
                && pools.data.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='engine'
          >
            <Select
              allowClear
              placeholder='Engine'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {engines
                && engines.engines.map((c) => (
                    <Option key={c} value={c}>
                      {c}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name='active' valuePropName={'checked'}>
            <Checkbox>Active</Checkbox>
          </Form.Item>
          <Form.Item
            name='customer_name'
            rules={[{ required: true, message: 'Please enter customer name!' }]}
          >
            <Select
              allowClear
              placeholder='Customer'
              optionFilterProp='children'
              showSearch
              onSearch={searchCustomers}
              onChange={(e) => {
                form.setFieldsValue({
                  hostname: e,
                });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers
                && customers.data.map((c) => (
                    <Option key={c.id} value={c.name}>
                      {c.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='hostname'
            rules={[{ required: true, message: 'Please enter hostname!' }]}
          >
            <Input placeholder='Hostname' />
          </Form.Item>
          <Form.Item name='showInitiallyFF' valuePropName={'checked'}>
            <Checkbox>Show Find.Fashion on load</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
