import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import styled from 'styled-components';

export default function TopActionButton({onClick, children, icon = <PlusOutlined/>}) {
  return (
    <ButtonWrapper>
      <Button onClick={onClick} className='addRuleButton'>
        {icon} {children}
      </Button>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  . {
    margin-right: 10px;
  }
  text-align: left;
  padding: 10px 0;
`;
