import './App.css';
import { useEffect } from 'react';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { useUserState } from './contexts/user-context';
import { isTokenValid } from './authentication';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');

function App() {
  const { userName } = useUserState();
  useEffect(() => loadAuthenticatedApp(), []);
  return (
    <div className="App">
      {userName && isTokenValid() ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  );
}

export default App;
