import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export default function LoginForm({ handleLogin }) {
  const handleSubmit = (values) => {
    handleLogin(values);
  };

  return (
    <Form onFinish={handleSubmit} autoComplete='off'>
      <Form.Item
        name='email'
        rules={[{ required: true, message: 'Please enter your email!' }]}
      >
        <Input prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input
          prefix={<LockOutlined />}
          type='password'
          placeholder='Password'
        />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}
