import {
  Modal,
  Form,
  Input,
  Select,
  notification,
  Spin,
  InputNumber,
  Switch,
} from 'antd';
import { useState } from 'react';
import _ from 'lodash';
import api from '../api';

const { Option } = Select;
const { TextArea } = Input;

export default function NewCollectionModal({
  visible,
  closeModal,
  getMessages,
  customers,
  selectedMessage,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const submit = async (values) => {
    const payload = {
      customer_id: values.customer_id,
      active: values.active,
      row: values.row,
      content: values.content,
    };
    setLoading(true);
    const url = _.isEmpty(selectedMessage)
      ? await api.post('/messaging-containers', payload)
      : await api.patch(
        `/messaging-containers/${selectedMessage.id}`,
        payload,
      );

    try {
      await url;
      notification.success({
        message: _.isEmpty(selectedMessage)
          ? 'Configuration successfully added'
          : 'Confituration successfully edited',
        position: 'top',
      });
      form.resetFields();
      setLoading(false);
      closeModal();
      getMessages();
    } catch ({ response }) {
      notification.error({
        message: `${response.data.message}`,
        placement: 'top',
      });
      setLoading(false);
    }
  };

  const {
    customer_id, active, row, content,
  } = selectedMessage;
  if (!_.isEmpty(selectedMessage)) {
    form.setFieldsValue({
      customer_id, active, row, content,
    });
  } else {
    form.resetFields();
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      destroyOnClose
      okButtonProps={{
        form: 'newConfigurationForm',
        htmlType: 'submit',
        key: 'submit',
      }}
    >
      <Spin spinning={loading}>
        <Form
          style={{ marginTop: '15px' }}
          id='newConfigurationForm'
          onFinish={submit}
          autoComplete='off'
          form={form}
          {...layout}
        >
          <Form.Item
            name='customer_id'
            rules={[{ required: true, message: 'Please select customer' }]}
            label='Customer'
          >
            <Select
              disabled={!_.isEmpty(selectedMessage)}
              allowClear
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers
                && customers.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.name}
                    </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name='active' label='Active'>
            <Switch defaultChecked={selectedMessage.active} />
          </Form.Item>
          <Form.Item
            label='Row'
            name='row'
            rules={[
              {
                required: true,
                message: 'Please add row!',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label='Content'
            name='content'
            rules={[{ required: true, message: 'Please add content' }]}
            style={{ margin: '24px 0px 0px 0px', textAlign: 'center' }}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
