import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  UserOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  MessageOutlined,
  AccountBookOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { useUserState } from '../../contexts/user-context';
import { removeToken } from '../../authentication';
import {
  Pools, ContainerMessages, Customers,
} from '../../pages';

const { Content, Sider } = Layout;

export default function Admin() {
  const navigate = useNavigate();
  const [collapsed, setCollapse] = useState(false);
  const [key, setKey] = useState('0');

  const onCollapse = (collapsed) => {
    setCollapse({ collapsed });
  };

  const pages = [
    {element: <Pools/>, icon: <AccountBookOutlined/>, name: 'Pools'},
    {element: <ContainerMessages/>, icon: <MessageOutlined />, name: 'Container Messages'},
    {element: <Customers/>, icon: <UsergroupAddOutlined/>, name: 'Customers'},
  ];

  const displayPage = () => pages[key].element;

  const { userName } = useUserState();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsed={collapsed} onCollapse={onCollapse}>
        <p style={{ color: 'white', textAlign: 'left', padding: '10px' }}>
          <UserOutlined />
          <span style={{ marginLeft: '5px', marginRight: '30px' }}>
            {userName}
          </span>
          <LogoutOutlined
            onClick={() => {
              removeToken();
              navigate('/login');
            }}
          />
        </p>
        <Menu
          theme="dark"
          defaultSelectedKeys={['0']}
          mode="inline"
          onClick={(item) => setKey(item.key)}
        >
          {
            pages.map((key, index) =>
              (<Menu.Item key={`${index}`} icon={key.icon}>
                {key.name}
              </Menu.Item>))
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: '24px 24px' }}>{displayPage()}</Content>
      </Layout>
    </Layout>
  );
}
