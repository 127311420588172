import { useEffect, useState } from 'react';
import { Button, Table, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import api from '../../api';
import AddEditCustomer from './AddEditCustomer';

export default function Customers() {
  const [page, setPage] = useState(0);
  const [customer, setCustomer] = useState(null);

  const changePage = (pageIndex, customer) => {
    setCustomer(customer);
    setPage(pageIndex);
  };

  const pageList = [
    <CustomersList changePage={changePage} key={'customer-list'}/>,
    <AddEditCustomer changePage={changePage} customer={customer} key={'add-edit-customer'} />,
  ];

  return pageList[page];
}

function CustomersList({ changePage }) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = () => {
    setLoading(true);
    api
      .get('/customers?$limit=10')
      .then((res) => {
        setCustomers(res.data.data);
        setTableData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setCustomers([]);
        setTableData([]);
        setLoading(false);
      });
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => b.name.length - a.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      width: '50%',
      render: (text, render) => {
        const customer = customers.find((c) => c.id === render.id);
        if (customer) {
          return (
            <ClientName onClick={() => changePage(1, customer)}>
              {customer.name}
            </ClientName>
          );
        }
      },
    },
    { title: 'Client ID', dataIndex: 'id', key: 'id' },
    { title: 'Feed', dataIndex: 'feed', key: 'feed' },
    { title: 'Feed Check Interval', dataIndex: 'feed_check_interval', key: 'feed_check_interval' },
    {
      title: 'Date Added',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, render) => moment(render.created_at).format('D. MMMM. YYYY - HH:mm:ss'),
    },
  ];

  const searchClient = (e) => {
    const value = e.target.value;
    const data = customers.filter((customer) =>
      customer.name.toLowerCase().includes(value));
    setTableData(data);
  };

  return (
    <PageWrapper>
      <Header>
        <h1>Clients</h1>
        <WidgetWrapper>
          <Input.Search
            placeholder="Search"
            width={50}
            className="searchInput"
            onChange={searchClient}
          />
          <Button onClick={() => changePage(1, null)} className="addRuleButton">
            <PlusCircleOutlined /> Add New Client
          </Button>
        </WidgetWrapper>
      </Header>
      <Table
        dataSource={tableData}
        columns={columns}
        loading={loading}
        className="customers-table"
        pagination={false}
        rowKey="id"
        key="id"
        onChange={handleChange}
      />
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  padding: 15px;
  background: #fff;
  width: 100%;
  height: 100%;

  .customers-table {
    th {
      font-weight: bold;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 8px;
  h1 {
    font-weight: bold;
    font-size: 18px;
  }
`;

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding: 10px 0;

  .searchInput {
    width: 264px;
    height: 32px;
    margin-right: 10px;
  }

  .addRuleButton {
    background: #1890ff;
    color: #fff;
    width: 150px;
    height: 32px;
  }
`;

const ClientName = styled.span`
  color: #1890ff;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;

  &:hover {
    cursor: pointer;
  }
`;
