import React, { useContext, useState } from 'react';

const UserStateContext = React.createContext();
const UserActionsContext = React.createContext();

function UserProvider({ children }) {
  const userName = localStorage.getItem('userName');

  const [user, setUser] = useState({ userName });

  function setUserName({
    userName, userType, fullName, id,
  }) {
    setUser({ userName });
    localStorage.setItem('userName', userName);
  }

  function deleteUserName() {
    setUser({ userName: null });
    localStorage.removeItem('userName');
  }
  return (
    <UserStateContext.Provider value={user}>
      <UserActionsContext.Provider value={{ setUserName, deleteUserName }}>
        {children}
      </UserActionsContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserActions() {
  const context = useContext(UserActionsContext);
  if (context === undefined) {
    throw new Error('useUserActions must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserActions };
