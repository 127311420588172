import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import LoginForm from './LoginForm';
import api from '../../api';
import { setToken } from '../../authentication';
import { useUserActions } from '../../contexts/user-context';

function Login() {
  const navigate = useNavigate();
  const { setUserName } = useUserActions();

  const handleLogin = (values) => {
    const { email, password } = values;
    api
      .post('/auth', {
        email,
        password,
        strategy: 'local',
      })
      .then((res) => {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('user', res.data.user.email);
        setUserName({
          userName: email,
        });
        setToken(res.data.accessToken);
        navigate('/admin');
      })
      .catch((err) => {
        err.response
          && notification.error({
            message: `${err.response.data.message}`,
            position: 'top',
          });
      });
  };

  return (
    <LoginWrapper>
      <LoginFormWrapper>
        <header></header>
        <h3>Welcome!</h3>
        <LoginForm handleLogin={handleLogin} />
      </LoginFormWrapper>
    </LoginWrapper>
  );
}

export default Login;

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(135deg, #b8e1f5 0, #f1f9fc 100%)
    repeat scroll 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginFormWrapper = styled.div`
  width: 450px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  background-color: white;
  i {
    color: rgba(0, 0, 0, 0.25);
  }
  button {
    width: 100%;
  }
  header {
    width: 100%;
    background: #333;
    padding: 18px;
  }
  form {
    padding: 30px 60px;
  }
  h3 {
    font-size: 25px;
    font-weight: 300;
    margin: 20px 0px;
    text-align: center;
  }
  img {
    width: auto;
    height: 22px;
  }
`;
