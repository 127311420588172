import {
  Button,
  Popconfirm,
  Space,
  Tag,
  notification,
} from 'antd';
import {
  CheckOutlined,
  WarningOutlined,
  DeleteTwoTone, PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import NewCollectionModal from '../modals/NewCollectionModal';
import api from '../api';
import PaginatedTable from '../components/tables/PaginatedTable';

export default function Collections() {
  const [collections, setCollections] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getCollections(1);
    getCustomers();
  }, []);

  const getCollections = async (page, pageSize) => {
    setLoading(true);
    const skip = (page - 1) * pageSize;
    try {
      const response = await api.get(`/collections?$skip=${skip}`);
      setLoading(false);
      setCollections(response.data);
    } catch ({ response }) {
      setLoading(false);
    }
  };

  const getCustomers = () => {
    api
      .get('/customers', { params: { $limit: 'all' } })
      .then((res) => setCustomers(res.data))
      .catch((err) => console.error(err));
  };

  const columns = [
    { title: 'Slug', dataIndex: 'slug', key: 'slug' },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'id',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'id',
      render: (text, render) => {
        const customer = customers.find((c) => c.id === render.customer_id);
        if (customer) {
          let color = 'blue';
          if (customer.name === 'swap.com') {
            color = 'blue';
          } else if (customer.name === 'footshop.cz') {
            color = 'green';
          } else if (customer.name === 'osta.ee') {
            color = 'gold';
          } else if (customer.name === 'FIND.Fashion') {
            color = 'cyan';
          }
          return <Tag color={color}>{customer.name}</Tag>;
        }
      },
    },
    {
      title: 'Reference item ID',
      dataIndex: 'item_id',
      key: 'item_id',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Ready',
      dataIndex: 'ready',
      key: 'ready',
      render: (text) => (text ? <CheckOutlined /> : <WarningOutlined />),
    },
    {
      title: 'Created date',
      key: 'date',
      dataIndex: 'date',
      render: (text, render) => moment(render.created_at).format('MMMM Do YYYY, h:mm:ss a'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Popconfirm
            placement='top'
            title='Are you sure you want to delete this collection?'
            onConfirm={() => {
              deleteCollection(record);
            }}
            okText='Yes'
            cancelText='No'
          >
            <DeleteTwoTone
              style={{ fontSize: '25px' }}
              twoToneColor='#c41c06'
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteCollection = async (record) => {
    setLoading(true);
    try {
      await api.delete(
        `/collections/${record.customer_id},${record.slug}`,
      );
      notification.success({
        message: `${record.name} successfully deleted!`,
        placement: 'top',
      });
      setLoading(false);
      getCollections();
    } catch ({ response }) {
      notification.error({
        message: `${response.data.message}`,
        placement: 'top',
      });
      setLoading(false);
    }
  };

  const addNewCollection = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };
  return (
    <>
      <ButtonWrapper>
        <Button onClick={() => addNewCollection()} className='addRuleButton'>
          <PlusOutlined /> Add new collection
        </Button>
      </ButtonWrapper>
      <PaginatedTable
        data={collections}
        loading={loading}
        onPageChange={(page, pageSize) => getCollections(page, pageSize)}
        columns={columns}
      />
      <NewCollectionModal
        visible={visible}
        closeModal={closeModal}
        getCollections={getCollections}
        setCustomers={setCustomers}
        customers={customers}
      />
    </>
  );
}

const ButtonWrapper = styled.div`
  .addRuleButton {
    margin-right: 10px;
  }
  text-align: left;
  padding: 10px 0;
`;
