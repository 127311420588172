import {useEffect, useState} from 'react';
import {
  Button, Pagination, Popconfirm, Space, Table, Tag,
} from 'antd';
import moment from 'moment';
import {DeleteTwoTone} from '@ant-design/icons';
import AddChildrenModal from '../modals/AddChildrenModal';
import api from '../api';

export default function MetaPools() {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedPool, setSelectedPool] = useState({});
  const [customers, setCustomers] = useState([]);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    fetchPools();
    fetchCustomers();
  }, []);

  const fetchPools = () => {
    setLoading(true);
    api
      .get('/pools?$limit=10')
      .then((res) => {
        setPools(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setPools([]);
      });
  };

  const fetchCustomers = () => {
    setLoading(true);
    api
      .get('/customers')
      .then((res) => setCustomers(res.data.data))
      .catch((err) => console.error(err));
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Customer',
      dataIndex: 'customer_id',
      key: 'customer_id',
      render: (text, render) => {
        const customer = customers.find(
          (customer) => customer.id === render.customer_id,
        );
        // return customer.name;
        let color = 'blue';
        if (customer) {
          if (customer.name === 'swap.com') {
            color = 'blue';
          } else if (customer.name === 'footshop.cz') {
            color = 'green';
          } else if (customer.name === 'osta.ee') {
            color = 'gold';
          } else if (customer.name === 'FIND.Fashion') {
            color = 'cyan';
          }
          return <Tag color={color}>{customer.name}</Tag>;
        }
      },
    },
    {
      title: 'Number of children',
      key: 'number_of_children',
      align: 'center',
      render: (text, render) => render.linked.length,
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, render) => moment(render.created_at).format('MMMM Do YYYY, h:mm:ss a'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text, render) => moment(render.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, render) => (
          <Button
            onClick={async () => {
              setSelectedPool(render);
              selectedPool && setVisible(true);
            }}
          >
            Add children
          </Button>
      ),
    },
  ];

  const closeModal = () => {
    setVisible(false);
  };

  const fetchAPIData = async (e) => {
    const skip = (e - 1) * 10;
    try {
      setLoading(true);
      const response = await api.get(`/pools?$limit=10&$skip=${skip}`);
      setPools(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const deletePool = (record) => {
    setLoading(true);
    api
      .delete(`pool/${record.id}`)
      .then(() => {
        setLoading(false);
        fetchAPIData(current);
      })
      .catch((err) => console.error(err));
  };
  const expandedRowRender = (data) => {
    const columns = [
      {
        title: 'Name', dataIndex: 'name', key: 'name', width: '50%',
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        width: '50%',
        align: 'right',
        render: (text, record) => (
          <Space size='middle'>
            <Popconfirm
              placement='top'
              title='Are you sure you want to remove this pool?'
              onConfirm={() => {
                deletePool(record);
              }}
              okText='Yes'
              cancelText='No'
            >
              <DeleteTwoTone
                style={{ fontSize: '25px' }}
                twoToneColor='#c41c06'
              />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey='id'
        showHeader={false}
        bordered={true}
      />
    );
  };

  return (
    <>
      <Table
        dataSource={pools.data}
        columns={columns}
        loading={loading}
        style={{ paddingTop: '40px' }}
        pagination={false}
        rowKey='id'
        key='id'
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.linked),
        }}
      />
      <Pagination
        onChange={(e) => {
          setCurrent(e);
          fetchAPIData(e);
        }}
        style={{ paddingBottom: '40px' }}
        total={pools.total}
        pageSize={10}
        defaultPageSize={10}
        defaultCurrent={1}
        current={current}
        showSizeChanger={false}
      />

      <AddChildrenModal
        visible={visible}
        closeModal={closeModal}
        selectedPool={selectedPool}
        fetchAPIData={fetchAPIData}
        current={current}
      />
    </>
  );
}
