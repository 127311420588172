import {useCallback, useEffect, useState} from 'react';
import {DeleteTwoTone, EditTwoTone} from '@ant-design/icons';
import {notification, Popconfirm, Space} from 'antd';
import styled from 'styled-components';
import TopActionButton from '../components/buttons/TopActionButton';
import PaginatedTable from '../components/tables/PaginatedTable';
import {service} from '../api';
import AddEditDemo from '../modals/AddEditDemo';

export function Demos() {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [demos, setDemos] = useState({});
  const [selectedDemo, setSelectedDemo] = useState({});

  const [demosService] = useState(service('/demos'));

  const getDemos = useCallback(async (page = 1, pageSize = 10) => {
    setLoading(true);
    const response = await demosService.find({limit: pageSize, skip: (page - 1) * pageSize});
    setDemos(response);
    setLoading(false);
    return response;
  }, [demosService]);

  useEffect(() => {
    const loadInitialData = async () => {
      await getDemos();
    };

    loadInitialData().then((r) => r);
  }, [getDemos]);

  const closeModal = () => {
    setModalVisible(false);
    setSelectedDemo({});
  };

  return (
    <>
      <TopActionButton onClick={() => setModalVisible(true)}>
        Add new Demo
      </TopActionButton>
      <PaginatedTable
        data={demos}
        loading={loading}
        onPageChange={(page, pageSize) => getDemos(page, pageSize)}
        columns={[
          { title: 'Listing Title', dataIndex: 'listingTitle', key: 'listingTitle' },
          { title: 'Slug', dataIndex: 'slug', key: 'slug' },
          { title: 'Pool', dataIndex: ['pool', 'name'], key: ['pool', 'name'] },
          {
            title: 'Engine',
            dataIndex: 'engine',
            key: 'engine',
            render: (text, render) => (text || 'Default'),
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, render) => (
                <ActionWrapper>
                  <EditTwoTone
                    style={{ fontSize: '25px' }}
                    twoToneColor='#69c0ff'
                    onClick={async () => {
                      setSelectedDemo(render);
                      selectedDemo && setModalVisible(true);
                    }}
                  />
                  <Space size='middle'>
                    <Popconfirm
                      placement='top'
                      title='Are you sure you want to delete this Demo?'
                      onConfirm={async () => {
                        await demosService.delete(render);
                        notification.success({
                          message: 'Demo has been successfully deleted',
                          position: 'top',
                        });
                        await getDemos();
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      <DeleteTwoTone
                        style={{ fontSize: '25px' }}
                        twoToneColor='#c41c06'
                      />
                    </Popconfirm>
                  </Space>
                </ActionWrapper>
            ),
          },
        ]}
      />
      <AddEditDemo
        visible={modalVisible}
        closeModal={closeModal}
        onSuccess={async () => {
          notification.success({
            message: `Demo successfully ${selectedDemo.id ? 'edited' : 'created'}`,
            placement: 'top',
          });
          setSelectedDemo({});
          await getDemos();
        }}
        demo={selectedDemo}
      />
    </>
  );
}

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
