import {
  Table,
  Tag,
  Space,
  Popconfirm,
  Button,
  notification,
} from 'antd';
import {
  DeleteTwoTone,
  PlusOutlined,
  EditTwoTone,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import api from '../api';
import NewMessagingConfigModal from '../modals/NewMessagingConfigModal';

export default function ContainerMessages() {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  useEffect(() => {
    getMessages();
    getCustomers();
  }, []);

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer_id',
      key: 'customer_id',
      render: (text, render) => {
        const customer = customers.find((c) => c.id === render.customer_id);
        if (customer) {
          let color = 'blue';
          if (customer.name === 'swap.com') {
            color = 'blue';
          } else if (customer.name === 'footshop.cz') {
            color = 'green';
          } else if (customer.name === 'osta.ee') {
            color = 'gold';
          } else if (customer.name === 'FIND.Fashion') {
            color = 'cyan';
          }
          return <Tag color={color}>{customer.name}</Tag>;
        }
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text, render) =>
        (render.active ? <CheckOutlined /> : <CloseOutlined />),
    },
    { title: 'Row', dataIndex: 'row', key: 'row' },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, render) => moment(render.created_at).format('MMMM Do YYYY, h:mm:ss a'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Popconfirm
            placement='top'
            title='Are you sure you want to delete this messaging container configuration?'
            onConfirm={() => {
              deleteConfiguration(record);
            }}
            okText='Yes'
            cancelText='No'
          >
            <DeleteTwoTone
              style={{ fontSize: '25px' }}
              twoToneColor='#c41c06'
            />
          </Popconfirm>
          <EditTwoTone
            style={{ fontSize: '25px', cursor: 'pointer' }}
            twoToneColor='#007C00'
            onClick={async () => {
              await setSelectedMessage(record);
              setVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const getMessages = () => {
    setLoading(true);
    api
      .get('/messaging-containers')
      .then((res) => {
        setMessages(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCustomers = () => {
    api
      .get('/customers', { params: { $limit: '1000' } })
      .then((res) => setCustomers(res.data.data))
      .catch((err) => console.error(err));
  };

  const addNewMessagingConfiguration = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setSelectedMessage({});
    setVisible(false);
  };

  const deleteConfiguration = async (record) => {
    setLoading(true);
    try {
      await api.delete(`/messaging-containers/${record.id}`);
      notification.success({
        message: 'Configuration successfully deleted!',
        placement: 'top',
      });
      setLoading(false);
      getMessages();
    } catch ({ response }) {
      notification.error({
        message: `${response.data.message}`,
        placement: 'top',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          onClick={() => addNewMessagingConfiguration()}
          className='addRuleButton'
        >
          <PlusOutlined /> Add new configuration
        </Button>
      </ButtonWrapper>
      <Table
        dataSource={messages}
        loading={loading}
        columns={columns}
        rowKey='id'
      />
      <NewMessagingConfigModal
        visible={visible}
        closeModal={closeModal}
        getMessages={getMessages}
        setCustomers={setCustomers}
        customers={customers}
        selectedMessage={selectedMessage}
      />
    </>
  );
}

const ButtonWrapper = styled.div`
  .addRuleButton {
    margin-right: 10px;
  }
  text-align: left;
  padding: 10px 0;
`;
