import {
  Modal, Form, Select, Checkbox, notification,
} from 'antd';
import { useState } from 'react';
import _ from 'lodash';
import api from '../api';

export default function AddChildrenModal({
  visible,
  closeModal,
  selectedPool,
  fetchAPIData,
  current,
}) {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const fetchPools = (e) => {
    if (!e) {
      setPools([]);
    } else {
      setPools([]);
      setLoading(true);
      api
        .get(
          `/pools?name[$ilike]=%${e}%&customer_id=${selectedPool.customer_id}`,
        )
        .then((res) => {
          setPools(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const submitForm = (values) => {
    setLoadingSuccess(true);
    api
      .post('/pool/links', { ...values, pool_id: selectedPool.id })
      .then((res) => {
        notification.success({
          message: 'Pools are successfully linked',
          placement: 'top',
        });
        setLoadingSuccess(false);
        form.resetFields();
        fetchAPIData(current);
        closeModal();
      })
      .catch((err) => {
        setLoadingSuccess(false);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        setPools([]);
        closeModal();
      }}
      okButtonProps={{
        form: 'addChildrenForm',
        htmlType: 'submit',
        key: 'submit',
      }}
      okText='Confirm'
      destroyOnClose
      loading={loadingSuccess}
    >
      <p style={{ textAlign: 'center', fontSize: 'large' }}>
        {' '}
        Pool name: {selectedPool.name}
      </p>
      <Form
        form={form}
        id='addChildrenForm'
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item name='twoway' valuePropName='checked'>
          <Checkbox>Cross recommendation</Checkbox>
        </Form.Item>
        <Form.Item
          name='linked_pool_ids'
          rules={[{ required: true, message: 'Please select children pool!' }]}
        >
          <Select
            style={{ width: '100%' }}
            mode='multiple'
            allowClear
            showArrow
            onSearch={_.debounce((e) => fetchPools(e), 500)}
            onDeselect={() => setPools([])}
            loading={loading}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {pools.length > 0
              && pools.map((pool) => (
                  <Select.Option key={pool.id} value={pool.id}>
                    {pool.name}
                  </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
