import moment from 'moment';
import jwtDecode from 'jwt-decode';

const tokenKey = 'accessToken';

export const setToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
};

export const isTokenValid = () => {
  const token = getCurrentUser();
  if (token && token.exp) {
    return moment().unix() < token.exp;
  }
  removeToken();
  return false;
};
