import axios from 'axios';
import { notification } from 'antd';
import { isTokenValid } from './authentication';

export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const readToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

export const api = axios.create({
  baseURL: API_ROOT,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  // set token for every API call
  (config) => {
    const token = readToken();
    config.headers = {
      Authorization: token,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    };
    return config;
  },
  (error) => Promise.reject(error),
);

const tokenValid = isTokenValid();
api.interceptors.response.use(null, (error) => {
  const expectedError = error.response
    && error.response.status >= 400
    && error.response.status <= 500;

  if (!expectedError) {
    notification.error({
      message: 'Unexpected server error.',
      position: 'top',
    });
  } else if (!tokenValid && error.response.status === 401) {
    notification.error({
      message: 'Session expired! Please, log in again.',
      position: 'top',
    });
  } else {
    notification.error({
      message: error.response.data.message,
      position: 'top',
    });
  }

  return Promise.reject(error.response);
});

export const service = (path) => ({
  async find({search = {}, limit, skip}) {
    const {term, column} = search;
    const url = path;
    const params = new URLSearchParams();
    if (term && term.length > 0) {
      params.append(`${column ?? 'name'}[$ilike]`, `%${term}%`);
    }
    if (limit) {
      params.append('$limit', limit);
    }
    if (skip) {
      params.append('$skip', skip);
    }

    const response = await api.get(url, {
      params,
    });
    return response.status === 200 ? response && response.data : null;
  },
  async create(data) {
    const response = await api.post(path, data);

    if (response.status < 300) {
      return response;
    }
  },
  async update(data) {
    const response = await api.put(`${path}/${data.id}`, data);

    if (response.status > 200 || response.status < 200) {
      return response;
    }
  },
  async delete(data) {
    const response = await api.delete(`${path}/${data.id}`);

    if (response.status > 200 || response.status < 200) {
      return response;
    }
  },
});

export default api;
